import { Box, InputBase, styled } from "@mui/material"
import { ImgBase } from "@components/images"
import { ButtonBase } from "@components/buttons"
import { Row, HSpace } from "@layout"
import { t } from "i18next"

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "10px",
  padding: "8px 10px",
  borderRadius: "12px",
  backgroundColor: theme.palette.background.gray_12
}))

const Input = styled(InputBase)(() => ({
  width: "100%",
  "& .MuiInputBase-input": {
    padding: 0,
    fontSize: "14px",
    fontWeight: 500
  }
}))

const InputSearch = ({ width, value, handleChange, handleSearch }) => {
  const handleEnterSearch = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value)
    }
  }

  return (
    <Row sx={{ width }}>
      <Wrapper sx={{ width: "100%" }} fullWidth={true}>
        <ImgBase width="20px" src="search-gray" />
        <Input
          placeholder={t("TEXT_COMMON_search")}
          value={value}
          onChange={(event) => handleChange(event.target.value)}
          onKeyDown={handleEnterSearch}
        />
      </Wrapper>
      <HSpace length="15" />
      <ButtonBase
        label={t("TEXT_COMMON_search")}
        padding="7.5px 43.5px"
        color="primary_dark"
        weight="500"
        border="transparent"
        borderradius="13px"
        onClick={handleSearch}
      />
    </Row>
  )
}

export default InputSearch
