import { FormControl } from "@mui/material"
import { Select, SelectItem, useStyles } from "./index.styles"
import { Text, Column } from "@layout"

const SelectBase = ({ list, onSelect, value, placeHolder, width = "100%", height, border, error, ...props }) => {
  const classes = useStyles({ listHeight: props?.listHeight })

  const handleChange = (event) => {
    let selectItem = null

    if (list?.length) {
      if (props.multiple) {
        let selectedArray = event.target.value || []
        selectItem = list.filter((item) => selectedArray.includes(item.id))
      } else {
        selectItem = list.find((item) => item.id === event.target.value)
      }
    }

    onSelect && onSelect(event.target, selectItem)
  }

  return (
    <FormControl sx={{ width, height }}>
      <Select
        color="secondary"
        value={value}
        onChange={handleChange}
        border={border}
        displayEmpty={true}
        error={error ? true : false}
        renderValue={value !== "" ? undefined : () => <Text size="14px">{placeHolder}</Text>}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
        {...props}
      >
        {list?.map((item, index) => (
          <SelectItem key={`select_item_${item.label}_${index}`} value={item.id} {...props}>
            {item.label}
          </SelectItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectBase
