import { useState, useEffect, useMemo } from "react"
import { styled } from "@mui/styles"
import { InputBase } from "@mui/material"
// import { debounce, isNumber } from "lodash"
import { isEmpty } from "@utils/validator"

const InputText = styled(({ onChange, value, regex, onKeyPress, ...rest }) => {
  const [newValue, setNewValue] = useState(value)

  useEffect(() => {
    setNewValue(value)
  }, [value])

  // const debounceSetInputText = useMemo(() => debounce((event) => onChange(event), 300), [])

  const handleChange = (event) => {
    if (regex && !regex(event.target.value) && !isEmpty(event.target.value)) return

    if (rest?.type === "number") {
      if (isEmpty(event.target.value)) {
        event.target.value = ""
      } else {
        event.target.value = Number(event.target.value)
      }
    }

    setNewValue(event.target.value)
    // debounceSetInputText(event)
    onChange(event)
  }

  const _onKeyPress = (e) => {
    if (onKeyPress) {
      onKeyPress(e.key)
    }
  }

  return <InputBase fullWidth={true} value={newValue} onChange={handleChange} onKeyPress={_onKeyPress} {...rest} />
  // return <InputBase fullWidth={true} value={newValue} onChange={handleChange} {...rest} />
})(({ theme, size, weight, bordercolor, borderradius, error, textalign, background }) => ({
  fontSize: size || "14px",
  fontWeight: weight || "300",
  padding: "2px 8px",
  flexGrow: 0,
  borderRadius: borderradius || "2px",
  border: "solid 1px",
  borderColor: error ? theme.border.red : theme.border[bordercolor ? bordercolor : "gray_3"],
  background: theme.palette.background[background || "default"],
  "& .MuiInputBase-input": { padding: 0 },
  "& .MuiInputBase-input::placeholder": { color: theme.palette.text.primary },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.background.gray_2,
    WebkitTextFillColor: theme.palette.text.primary
  },
  "& input": { textAlign: textalign || "normal" }
}))

export default InputText
