import { useState } from "react"
// Components
import Box from "@mui/material/Box"
import { LocalizationProvider, DatePicker } from "@mui/lab"
import { dateAdapter } from "@components/datePicker/common"
import { InputText } from "@components/inputs"
// Libs

const InputDatePicker = ({ id, value, handleChange, error, disabled, ...rest }) => {
  const [open, setOpen] = useState(false)

  return (
    <LocalizationProvider dateAdapter={dateAdapter}>
      <DatePicker
        label="input date picker"
        inputFormat="YYYY-MM-DD"
        mask="____-__-__"
        open={open}
        disabled={disabled}
        onClose={() => setOpen(false)}
        value={value ? new Date(value) : null}
        onChange={handleChange}
        renderInput={({ inputRef, inputProps, InputProps }) => {
          return (
            <Box sx={{ display: "flex", alignItems: "center", pointerEvents: disabled ? "none" : "auto" }}>
              <InputText
                {...inputProps}
                error={error ? true : false}
                disabled={disabled}
                inputRef={inputRef}
                onClick={() => setOpen(!open)}
              />
            </Box>
          )
        }}
        {...rest}
      />
    </LocalizationProvider>
  )
}

export default InputDatePicker
