import React, { useState } from "react"
import InputBase from "./input-base"
import { InputAdornment } from "@mui/material"
import { ImgButton } from "@components/images"

const InputPassword = (props) => {
  const [show, setShow] = useState(false)

  const handleClickShowPassword = () => setShow(!show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <InputBase
      type={show ? "text" : "password"}
      {...props}
      endAdornment={
        <InputAdornment position="end">
          <ImgButton
            width="20px"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            src={`carbon-view-${show ? "off-" : ""}filled`}
          />
        </InputAdornment>
      }
    />
  )
}

export default InputPassword
