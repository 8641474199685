import { styled } from "@mui/styles"
import Button from "@mui/material/Button"
import { ImgBase } from "../images"

const Base = ({ src, srcx, alt, label, imgW = "25px", imgH = "25px", children, ...rest }) => {
  return (
    <Button variant="outline" {...rest} src={src} srcx={srcx}>
      {label}
      {(src || srcx) && <ImgBase src={src} srcx={srcx} alt={alt} width={imgW} height={imgH} />}
    </Button>
  )
}
const ButtonOutlined = styled(Base)(({ theme, width, src, srcx }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: width,
  fontSize: "14px",
  fontWeight: 300,
  color: theme.palette.text.black,
  lineHeight: "normal",
  letterSpacing: "normal",
  padding: src || srcx ? "7.5px 12px" : "7.5px 6.5px",
  border: "solid 1px",
  borderColor: theme.border.gray_7,
  borderRadius: "8px",
  backgroundColor: theme.palette.background.gray_2,
  gap: "6px",
  boxShadow: "none !important",
  "&:hover": {
    backgroundColor: `${theme.palette.background.gray_10} !important`
  }
}))

export default ButtonOutlined
