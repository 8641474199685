import { styled } from "@mui/styles"
import Button from "@mui/material/Button"
import { ImgBase } from "../images"

const Base = ({ src, srcx, alt, label, imgW = "21px", imgH = "21px", error, children, ...rest }) => {
  return (
    <Button variant="contained" {...rest}>
      {(src || srcx) && <ImgBase src={src} srcx={srcx} alt={alt} width={imgW} height={imgH} />}
      {label}
      {children && children}
    </Button>
  )
}
const ButtonWithImage = styled(Base)(
  ({ theme, textcolor, direction, gap, size, weight, padding, width, backgroundcolor, borderradius, error, hide }) => {
    let style = {
      fontSize: size || "14px",
      fontWeight: weight || 500,
      lineHeight: "normal",
      letterSpacing: "normal",
      display: "flex",
      flexDirection: direction || "row",
      justifyContent: "center",
      alignItems: "center",
      padding: padding || "7px 20px",
      borderRadius: borderradius || "13px",
      gap: `${gap || 10}px`,
      boxShadow: "none !important",
      width: width,
      whiteSpace: "nowrap",
      border: "1px solid",
      borderColor: theme.border[error ? "red" : "transparent"]
    }
    if (backgroundcolor) {
      style.backgroundColor = theme.palette.background[backgroundcolor]
    }
    if (textcolor) {
      style.color = theme.palette.text[textcolor]
    }
    if (hide) {
      style.opacity = 0
      style.pointerEvents = "none"
    }
    return style
  }
)

export default ButtonWithImage
