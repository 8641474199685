import { styled } from "@mui/styles"
import { InputBase } from "@mui/material"

const Input = styled((props) => <InputBase {...props} fullWidth={true} />)(
  ({ theme, size, weight, radius, padding, bordercolor, center }) => ({
    fontSize: size || "16px",
    fontWeight: weight || "normal",
    flexGrow: 0,
    padding: padding || "9px 13px",
    borderRadius: radius || "8px",
    border: "solid 1px",
    borderColor: theme.border[bordercolor ? bordercolor : "brown"],
    "& .MuiInputBase-input": { textAlign: center && "center" },
    "& .MuiInputBase-input::placeholder": { color: theme.palette.text.primary }
  })
)

export default Input
