import { Select as muiSelect, MenuItem as muiMenuItem, styled } from "@mui/material"
import assetPrefix from "@prefix"
import SelectUnstyled from "@mui/base/SelectUnstyled"
import { makeStyles } from "@mui/styles"

export const Select = styled(muiSelect)(({ theme, border, size, weight, padding, error }) => {
  return {
    fontSize: size || "12px",
    fontWeight: weight || "normal",
    background: theme.palette.background.default,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiSelect-select": {
      padding: padding || "2px 6px",
      borderRadius: "2px",
      border: border ? border : "1px solid",
      borderColor: error ? theme.border.red : theme.border.gray_3,
      margin: 0,
      "&[aria-expanded=true]": {
        borderBottom: "none",
        borderRadius: "2px 2px 0px 0px"
      },
      "&[aria-disabled=true]": {
        backgroundColor: theme.palette.background.gray_2,
        WebkitTextFillColor: theme.palette.text.primary
      }
    },
    "& .MuiSvgIcon-root": {
      top: "calc( 50% - 5px)",
      right: "10px",
      backgroundImage: `url(${assetPrefix}/images/bx-bxs-down-arrow@3x.png)`,
      width: "10px",
      height: "10px",
      backgroundPosition: "center",
      backgroundSize: "cover",
      transition: "all 0.3s"
    }
  }
})

export const SelectItem = styled(muiMenuItem)(({ theme, size, weight }) => ({
  fontSize: size || "12px",
  fontWeight: weight || "normal",
  padding: "5px 6px",
  "&:hover": {
    backgroundColor: theme.palette.background.gray_2
  }
}))

export const useStyles = makeStyles({
  menuPaper: {
    maxHeight: ({ listHeight }) => listHeight || "300px"
  }
})
