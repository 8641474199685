import DateIOAdapter from "@mui/lab/AdapterMoment"

export function dateAdapter(options) {
  const adapter = new DateIOAdapter(options)

  const constructUpperObject = (text) => ({ toUpperCase: () => text })
  const constructDayObject = (day) => ({ charAt: () => constructUpperObject(day) })

  return {
    ...adapter,
    // formats: {
    //   ...adapter.formats,
    //   monthAndYear: "YYYY MMMM"
    // },
    getWeekdays() {
      const customWeekdays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
      // const customWeekdays = adapter.getWeekdays()
      return customWeekdays.map((day) => constructDayObject(day))
    }
  }
}

export const seasons = [
  [1, 2, 3, 4],
  [5, 6, 7, 8],
  [9, 10, 11, 12]
]

export const findDate = (dates, date) => {
  return dates.find((item) => item.format("YYYY-MM-DD") == date.format("YYYY-MM-DD"))
}

export const findIndexDate = (dates, date) => {
  return dates.findIndex((item) => item.format("YYYY-MM-DD") == date.format("YYYY-MM-DD"))
}
