import { makeStyles, useTheme } from "@mui/styles"
import { Box } from "@mui/material"
import { FieldLabel } from "@layout"

const useStyles = makeStyles({
  label: {
    fontSize: ({ size }) => size || "16px",
    fontWeight: ({ weight }) => weight || "400",
    margin: "0 0 5px 0",
    color: ({ theme, error, errorMessage }) => theme.palette.text[error && !errorMessage ? "red" : "black"]
  },
  errorMessage: {
    fontSize: ({ size }) => size || "16px",
    fontWeight: ({ weight }) => weight || "400",
    margin: "5px 0 0 0",
    userSelect: "none",
    color: ({ theme, error }) => theme.palette.text[error ? "yellow" : "transparent"]
  }
})

const InputWrapper = ({
  label,
  error,
  errorMessage,
  fullWidth,
  width,
  children,
  size,
  weight,
  required = false,
  ...rest
}) => {
  const theme = useTheme()
  const classes = useStyles({ theme, error, errorMessage, size, weight })
  return (
    <Box sx={{ width: fullWidth ? "100%" : width, alignSelf: "flex-start", ...rest }}>
      <FieldLabel size={size} weight={weight} required={required}>
        {label}
      </FieldLabel>
      {children}
      {errorMessage && <p className={classes.errorMessage}>{error && errorMessage}</p>}
    </Box>
  )
}

export default InputWrapper
