import { styled } from "@mui/styles"
import Button from "@mui/material/Button"

const Base = ({ label, ...rest }) => {
  return (
    <Button variant="contained" {...rest}>
      {label}
    </Button>
  )
}
const ButtonBase = styled(Base)(
  ({
    theme,
    width,
    textcolor,
    size,
    weight,
    padding,
    background,
    border,
    borderradius,
    error = "false",
    disabled,
    fullWidth,
    ...props
  }) => {
    let style = {
      fontSize: size || 14,
      fontWeight: weight || 400,
      width: fullWidth ? "100%" : width || "auto",
      lineHeight: "normal",
      letterSpacing: "normal",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: padding || "7px 12px",
      borderRadius: borderradius || "8px",
      boxShadow: "none !important",
      whiteSpace: "nowrap",
      "&.Mui-disabled": {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.gray
      }
    }
    if (textcolor) {
      style.color = theme.palette.text[textcolor]
    }
    if (background) {
      style.backgroundColor = theme.palette.background[background]
    }
    if (error == "true") {
      style.border = `1px solid ${theme.border.red}`
    } else {
      style.border = `1px solid ${theme.border[border ? border : "gray_7"]}`
    }
    return style
  }
)

export default ButtonBase
