import { useState, useEffect, useMemo, useCallback } from "react"
import { styled } from "@mui/styles"
import { InputBase } from "@mui/material"
import { debounce } from "lodash"

const Base = ({ ...rest }) => {
  return <InputBase {...rest} multiline={true} />
}

const InputTextArea = styled(Base)(
  ({ theme, width, height, border, padding, borderradius, backgroundcolor, error, disabled }) => {
    let style = {
      fontSize: "14px",
      fontWeight: "300",
      padding: padding || "2px 12px",
      borderRadius: borderradius || "4px",
      width: width || "330px",
      height: height || "70px",
      border: border || `solid 1px ${theme.border.gray}`,
      backgroundColor: backgroundcolor || theme.palette.background.default,
      borderColor: theme.border[error ? "red" : "gray_3"],
      "& .MuiInputBase-input::placeholder": { color: theme.palette.text.primary },
      "& textarea": {
        overflowY: "auto !important",
        height: "100% !important"
      },
      "& .Mui-disabled": {
        backgroundColor: theme.palette.background.gray_2,
        WebkitTextFillColor: theme.palette.text.primary
      }
    }
    if (disabled) {
      style.backgroundColor = theme.palette.background.gray_2
    }
    return style
  }
)

// export default InputTextArea

const InputTextAreaDebounce = ({ onChange, value, ...rest }) => {
  const [newValue, setNewValue] = useState(value)

  useEffect(() => {
    setNewValue(value)
  }, [value])

  const debounceSetInputText = useMemo(
    () =>
      debounce((val, onChange) => {
        onChange(val)
      }, 500),
    []
  )

  return (
    <InputTextArea
      {...rest}
      value={newValue}
      onChange={(event) => {
        setNewValue(event.target.value)
        debounceSetInputText(event, onChange)
      }}
    />
  )
}

export default InputTextAreaDebounce
