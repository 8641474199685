import { phoneOptionList } from "@constants"
import { formatPhoneToField, formatPhoneToData } from "@utils/formatter"

import { InputText } from "@components/inputs"
import { SelectBase } from "@components/selects"
import { Row } from "@layout"
import { useTheme } from "@mui/styles"
import { useEffect } from "react"

const InputPhone = ({ value, error, handleChange }) => {
  const theme = useTheme()

  useEffect(() => {
    const data = formatPhoneToField(value)
    // console.log({ value, data })
    if (!data.countryCode) {
      handleChange("phone", "select", formatPhoneToData(value, "countryCode", phoneOptionList[0].id))
    }
  }, [value])
  return (
    <Row sx={{ border: `1px solid ${theme.border[error ? "red" : "gray_3"]}`, borderRadius: "2px" }} fullWidth="true">
      <SelectBase
        width="100px"
        value={formatPhoneToField(value).countryCode}
        border="none"
        list={phoneOptionList}
        size="14px"
        weight="300"
        onSelect={(event) => handleChange("phone", "select", formatPhoneToData(value, "countryCode", event.value))}
      />
      <InputText
        sx={{ border: "none !important" }}
        value={formatPhoneToField(value).number}
        onChange={(event) => handleChange("phone", "input", formatPhoneToData(value, "number", event.target.value))}
      />
    </Row>
  )
}

export default InputPhone
