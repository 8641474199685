import { styled } from "@mui/styles"
import { ImgBase } from "../images"
import { Text } from "@layout"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { useTheme } from "@mui/styles"

const Base = ({ children, label, nubmerLabel, show, selected, disabled, ...rest }) => {
  const theme = useTheme()
  
  return (
    <Box {...rest} style={buttonWithNumberStyle({ show, selected, disabled, theme })}>
      {show ? (
        <>
          {nubmerLabel ? (
            <Text
              style={{
                fontSize: "12px",
                width: "19px",
                height: "19px",
                backgroundColor: "#fac043",
                borderRadius: "53px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {nubmerLabel}
            </Text>
          ) : null}
          <Text>{label}</Text>
          {selected && !disabled ? (
            <ImgBase srcx={"yellow-delete"} alt={"hide icon"} width={"12px"} height={"12px"} />
          ) : null}
        </>
      ) : (
        <Text style={{ height: "20px", display: "flex", alignItems: "center" }}>{label}</Text>
      )}
    </Box>
  )
}
const ButtonWithNumberClose = styled(Base)(({ theme, width }) => {
  let style = {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "normal",
    letterSpacing: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 6px",
    border: `1px solid ${theme.border.gray}`,
    borderRadius: "0px",
    gap: "10px",
    boxShadow: "none !important"
  }
  if (width) {
    style.width = width
  }

  return style
})

export const buttonWithNumberStyle = ({ show, selected, disabled, theme }) => {
  let style = {}, backgroundColor = "gray"
  
  if (show) {
    backgroundColor = selected ? "gray_8" : "paper"
  } else {
    style.color = theme.palette.text.brown_1
  }
  if (show && selected && disabled) {
    backgroundColor = "gray_8"
  }
  if (disabled) {
    style.cursor = "default"
    style["pointerEvents"] = "none"
  } else {
    style.cursor = "pointer"
  }

  style.backgroundColor = `${theme.palette.background[backgroundColor]}`
  style["&:hover"] = {
    backgroundColor: `${theme.palette.background[backgroundColor]}`
  }

  return style
}

export default ButtonWithNumberClose
